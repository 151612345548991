
    import { Component, Vue } from 'vue-property-decorator'
    import { Action } from 'vuex-class'
    @Component
    export default class App extends Vue {
        // @Action('getPermission') getPermission!: () => void
        @Action('getConfig') getConfig! : () => void
        async created() {
            // this.getPermission()

            await this.getConfig()
            // 获取request中的url，并获取商家ID，并存储以备后用
            var url = window.location.href;             //获取当前url
            var dz_url = url.split('#')[0];                //获取#/之前的字符串
            var cs = dz_url.split('?')[1];                //获取?之后的参数字符串
            var cs_arr = cs.split('&');                    //参数字符串分割为数组
            for (var i = 0; i < cs_arr.length; i++) {         //遍历数组，拿到json对象
                var tmp = cs_arr[i].split('=');
                if (tmp[0] == 'sid') {
                    localStorage.setItem('sid', tmp[1]);
                    break;
                }
            }

            // 动态设置站点图片
            const link = this.$store.getters.config.platform_ico
            let $favicon : any = document.querySelector('link[rel="icon"]')
            if ($favicon !== null) {
                $favicon.href = link
            } else {
                $favicon = document.createElement('link')
                $favicon.rel = 'icon'
                $favicon.href = link
                document.head.appendChild($favicon)
            }
        }
    }
